
/* eslint-disable */
import { defineComponent, ref, watch } from 'vue';
import moment from 'moment';

export default defineComponent({
  components: {
  },
  setup() {
    //#region Data
    const dobValue = ref('');
    const diffValue = ref('');
    //#endregion
    
    //#region Methods
    
    //#endregion

    //#region Watcher
    watch(dobValue, (val) => {
      if (val && val.length == 8) {
        const todayDate = moment();
        const dobDate = moment(val, 'DDMMYYYY');
        
        const years = todayDate.diff(dobDate, 'years');
        dobDate.add(years, 'years');

        const months = todayDate.diff(dobDate, 'months');
        dobDate.add(months, 'months');

        const days = todayDate.diff(dobDate, 'days');

        diffValue.value = `${years} Year ${months} Month ${days} Day`;
      } else {
        diffValue.value = '';
      }
    })
    //#endregion
    
    return {
      //#region Data
      dobValue,
      diffValue,
      //#endregion
      
      //#region Methods
      
      //#endregion
    }
  },
})
