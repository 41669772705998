import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-657cb7ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "exact-age-main" }
const _hoisted_2 = { style: {"margin-top":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      placeholder: "DOB in ddMMyyyy",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dobValue) = $event))
    }, null, 512), [
      [_vModelText, _ctx.dobValue]
    ]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.diffValue), 1)
  ]))
}